import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


let config = {
    apiKey: "AIzaSyBtE85RN6g-wbSqKm0x3I4Yc9VSkuDJuYk",
    authDomain: "openaiapi.firebaseapp.com",
    databaseURL: "https://openaiapi.firebaseio.com",
    projectId: "openaiapi",
    storageBucket: "openaiapi.appspot.com",
    messagingSenderId: "1047223436410",
    appId: "1:1047223436410:web:257acea70e0c3bfb2fe7c3"
  }
  
firebase.initializeApp(config);

export default firebase;