import firebase from "./Firebase";
import "../styles/Sidebar.css";

// const firestore = firebase.firestore();
const db = firebase.firestore();

function Sidebar({ setSettings, setMode, openModal, setDetails, setAllSettings }) {
  function ToggleSidebar() {
    
    let sidebar = document.getElementById("Sidebar");
    let inner = document.getElementById("InnerArrow");

    sidebar.style.display = sidebar.style.display === "block" ? "none" : "block";
  }

 

  // Search input
  function onKeyPress(e) {
    if (e.key === "Enter") {
      let query = e.target.value;
      Search(setSettings, setMode, query, openModal, setDetails, setAllSettings);
    }
  }

  return (
    <div>
       
      <div id="OpenArrow" onClick={() => ToggleSidebar()}>
       <span id="OuterArrow" className="fade-in" style={{marginRight: "12px",}}></span> <i className="fas fa-chevron-right"></i>
      </div>

      <div id="Sidebar"  style={{width: "340px", display: "block"}}>
 

        <div id="CloseArrow" onClick={() => ToggleSidebar()}>
        Creator Interface by @AndrewMayne<span id="InnerArrow" style={{marginLeft: "12px"}}></span> <i className="fas fa-chevron-left"> </i>
        </div>
         
         
        <div id="SearchDiv">
            
          <input
            id="SearchInput"
            onKeyPress={(e) => onKeyPress(e)}
            placeholder="Prompt Directory search"
          ></input>
        </div>
        <div id="SearchResults"></div>
         
      </div>
    </div>
  );
}

function Search(setSettings, setMode, query, openModal, setDetails, setAllSettings) {
  document.getElementById("SearchResults").innerHTML = "";

  db.collection("prompts")
    .where("keywords", "array-contains", query.toLowerCase())
    .limit(20)
    .get()
    .then(function (querySnapshot) {
      if (querySnapshot.size > 0) {
        // Contents of first document

        querySnapshot.forEach((doc) => {
          let data = doc.data();

          const promptSettings = data.settings;

          if (promptSettings.engine === undefined) {
            promptSettings.engine = "davinci";
          }

          // Left side data
          var resultBox = document.createElement("div");
          resultBox.classList.add("search-result");

          var div = document.createElement("div");
          div.classList.add("prompt-icon");
          div.innerHTML = data.icon ?? "🤖";
          resultBox.appendChild(div);

          var div = document.createElement("div");
          div.classList.add("search-left-side");

          var title = document.createElement("div");
          title.classList.add("search-result-title");
          title.innerHTML = data.title;
          div.appendChild(title);

          var description = document.createElement("div");
          description.classList.add("search-result-description");
          if (
            data.description.split(" ").length > 10 ||
            data.description.split("").length > 100
          ) {
            description.innerHTML =
              data.description.split(" ").slice(0, 10).join(" ") + "...";
          } else {
            description.innerHTML = data.description;
          }

          div.appendChild(description);

          var button = document.createElement("div");
          button.onclick = function () {

            
            setDetails(data);
             
            openModal();
          };

          button.classList.add("prompt-detail-button");
          button.innerHTML = "More info";
          div.appendChild(button);

          resultBox.appendChild(div);

          // Right side data
          var div = document.createElement("div");
          div.classList.add("search-right-side");

          // Add button
          var button = document.createElement("button");
          button.innerHTML = "Add";
          button.classList.add("search-add-button");
          button.onclick = function () {
            // console.log("Apply prompt settings", promptSettings);
            //setValues(data, doc.id);
            document.getElementById("PromptDisplay").value = data.prompt;
            //setSettings(promptSettings);
            setDetails(data);
            setMode(data.app);

            setAllSettings(data.settings)
            
          };

          div.appendChild(button);
          resultBox.appendChild(div);

          var div = document.createElement("div");
          div.setAttribute("style", "clear:both");
          resultBox.appendChild(div);

          document.getElementById("SearchResults").appendChild(resultBox);
        });
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document: ", error);
    });

  console.log("Run search");
}

export default Sidebar;
