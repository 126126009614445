import React from "react";


function Workspace({ 
    settings, 
    changeSetting, 
    apiKey, 
    setKey, 
    promptText, 
    setPrompt, 
    apiCallButton, 
    mode, 
    setMode, 
    engines,
    SubmitBar,
    ApiKeyHandler,
    PromptWindow,
    ChatWindow,
    QAWindow,
    totalTokens,
    user,
    openModal
}) {

    function toggleSettings(){
      const bar = document.getElementById("SettingsPanel");
      //const stats = document.getElementById("StatsWindow");
      if(bar.style.display === "block"){
        bar.style.display = "none";  
        //stats.style.display = "none";
      } else {
        bar.style.display = "block";
        //stats.style.display = "block";
      }
    }

  
    return (
      <div id="MainWindow">
      <div className="menu-tab">
        <div className="menu-icon" onClick={() => toggleSettings()}>
          <div className='div_line'></div><div className='div_line'></div><div className='div_line'></div>
        </div>
      </div>
      <div id="Workspace">
        <div id="SettingsPanel" style={{display: "block", width: "800px"}}>
        <div className="Workbar">
          <div className="SettingsBar"  >
            <div className="first-column">
              <label className="settings-label">
                <h2>Engine</h2>
                <select
                  className="engine-select"
                  defaultValue={settings.engine}
                  onChange={(e) => {
                    changeSetting("engine", e.target.value);
                  }}
                >
                  {engines.map((engine) => (
                    <option key={engine.id} value={engine.name}>
                      {engine.name}
                    </option>
                  ))}
                </select>
              </label>
              <br></br>
              <br></br>
  
              <label>
                <div className="settings-label">Max tokens </div>
                <input
                  className="settings-number-large"
                  type="number"
                  value={settings.length}
                  onChange={() => {}}
                ></input>
              </label>
              <br></br>
              <br></br>
            </div>
  
            <div className="second-column">
              <div className="setting">
                <label>
                  <input
                    className="settings-number"
                    type="number"
                    min={0}
                    max={1.0}
                    step={0.01}
                    value={settings.temperature ?? 0.3 }
                    onChange={(e) => {
                      e.target.value = e.target.value < e.target.max ? e.target.value : e.target.max;
                      changeSetting("temperature", e);
                    }}
                  ></input>
                  <span className="number-label">Temperature</span>
                </label>
              </div>
  
              <div className="setting">
                <label>
                  <input
                    className="settings-number"
                    type="number"
                    min={0}
                    max={1.0}
                    step={0.01}
                    value={settings.top_p ?? 0.0}
                    onChange={(e) => {
                      e.target.value = e.target.value < e.target.max ? e.target.value : e.target.max;
                      changeSetting("top_p", e.target.value); 
                    }}
                  ></input>
                  <span className="number-label">Top P</span>
                </label>
              </div>
  
              <div className="setting">
                <label>
                  <input
                    className="settings-number"
                    type="number"
                    min={0}
                    max={1.0}
                    step={0.01}
                    value={settings.frequency_penalty ?? 0.0}
                    onChange={(e) => {
                      e.target.value = e.target.value < e.target.max ? e.target.value : e.target.max;
                      changeSetting("frequency", e.target.value);
                    }}
                  ></input>
                  <span className="number-label">Frequency penalty</span>
                </label>
              </div>
  
              <div className="setting">
                <label>
                  <input
                    className="settings-number"
                    type="number"
                    min={0}
                    max={1.0}
                    step={0.01}
                    value={settings.presence_penalty ?? 0.0}
                    onChange={(e) => {
                      e.target.value = e.target.value < e.target.max ? e.target.value : e.target.max;
                      changeSetting("presence", e.target.value);
                    }}
                  ></input>
                  <span className="number-label">Presence penalty</span>
                </label>
              </div>
  
              <div className="setting">
                <label>
                  <input
                    className="settings-number"
                    type="number"
                    value={settings.best_of ?? 1}
                    onChange={(e) => {
                      changeSetting("best_of", e.target.value);
                    }}
                  ></input>
                  <span className="number-label">Best Of</span>
                </label>
              </div>
            </div>
  
            <div className="second-column">
              <input
                className="text-setting"
                placeholder="Stop sequence"
                value={settings.stop ?? ""}
                onChange={(e) => {
                  changeSetting("stop", e.target.value);
                }}
              ></input>
  
              <input
                className="text-setting"
                placeholder="Inject start text"
                value={settings.start ?? ""}
                onChange={(e) => {
                  changeSetting("start", e.target.value);
                }}
              ></input>
  
              <input
                className="text-setting"
                placeholder="Inject re-start text"
                value={settings.restart ?? ""}
                onChange={(e) => {
                  changeSetting("restart", e.target.value);
                }}
              ></input>
  
              <div className="setting">
                <label> 
                  <input type="checkbox" defaultChecked={true} onChange={(e) => { ToggleStats(e.target.checked) }}></input>
                  Show stats
                </label>
              </div>
  
              <div className="setting" >
                <label>
                  <input type="checkbox" defaultChecked={false} onChange={(e) => { ToggleEstimate(e.target.checked) }}></input>
                  Show pricing estimate
                </label>
              </div>
  
              <div className="setting" >
                <label>
                  <input type="checkbox" defaultChecked={true} onChange={(e) => {document.getElementById("PromptWindow").style.display = e.target.checked ? "block" : "none" }}></input>
                  Show prompt window
                </label>
              </div>
  
  
  
            </div>
          </div>
        </div>
        <div className="key-box-inner">
        <div className="key-box">
          <ApiKeyHandler apiKey={apiKey} setKey={setKey} />
        </div>
      
  
        </div>
      </div>


   
        <PromptWindow promptText={promptText} setPrompt={setPrompt} />

   
  
        <ChatWindow />
  
        <QAWindow  />
  
        <StatsWindow totalTokens={totalTokens} />
  
      </div>
        <SubmitBar apiCallButton={apiCallButton} openModal={openModal} user={user} mode={mode} setMode={setMode} promptText={promptText} settings={settings} />
      </div>
    );
  }

 


function ToggleStats(show){

    console.log("Stats:", show)
    const stats = document.getElementById("StatsWindow");
    if(!show){
      stats.style.display = "none";
    } else {
      stats.style.display = "block";
    }
}

function ToggleEstimate(show){

  console.log("Estimate:", show)
  const div = document.getElementById("CostDisplay");
  if(!show){
    div.style.display = "none";
  } else {
    div.style.display = "block";
  }
}

 

function StatsWindow({totalTokens}) {
  return (
    <div id="StatsWindow">
      <div id="TokenDisplay" className="stat-display">Total tokens: <span id="TokenCount" className="stat">{totalTokens.tokens}</span> </div>
      <div id="CostDisplay" style={{display: "none"}}  className="stat-display">Estimated cost: <span id="CostEstimate" className="stat">${totalTokens.cost.toFixed(6)}</span> </div>
    </div>
  )
}




export default Workspace;
  