import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import axios from "axios";
import "./styles/Workspace.css";
import Workspace from "./scripts/Workspace";
import Sidebar from "./scripts/Sidebar";
import firebase from "./scripts/Firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";


const adminMode = true;

const db = firebase.firestore();

const auth = firebase.auth();

const initialKey = "";

const tokenPrice = 0.06;

const customStyles = {
  content: {
    width: "700px",
    height: "900px",
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  },
};

Modal.setAppElement("#root");

const engines = [
  { id: 1, name: "davinci", cost: 1 },
  { id: 2, name: "curie", cost: 10 },
  { id: 3, name: "babbage", cost: 50 },
  { id: 4, name: "ada", cost: 75 },
];

const engineCosts = {
  davinci: 1,
  curie: 10,
  babbage: 50,
  ada: 75,
};

const initialSettings = {
  length: 50,
  engine: "davinci",
  stop: "",
  temperature: 0.3,
  top_p: 0.0,
  frequency_penalty: 0.0,
  presence_penalty: 0.0,
  best_of: 1,
  start: "",
  restart: "",
};

const prompt = "";

const completionInputText = "";

const modes = [
  { id: 1, name: "document" },
  { id: 2, name: "chat" },
  { id: 3, name: "question" },
];

const initialPromptDetails = {};

var showSaveOptions = false;


var promptLoaded = false;

var lastPrompt = "";



function App() {
  const [user] = useAuthState(auth);
  const [promptText, setPromptText] = useState(prompt);
  const [settings, setSettings] = useState(initialSettings);
  const [apiKey, setApiKey] = useState(initialKey);
  const [chatText, setChatText] = useState("");
  const [completionText, setCompletionText] = useState(completionInputText);
  const [mode, setModeValue] = useState(modes[0]);
  const [promptDetails, setPromptDetails] = useState(initialPromptDetails);
  const [totalTokens, setTotalTokens] = useState({ tokens: 0, cost: 0 });
  const [tokenCost, setTokenCost] = useState(0);

  var subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(option) {
    if (option === "saveOptions") {
      showSaveOptions = true;
    } else {
      showSaveOptions = false;
    }
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "black";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const updateTokens = (count) => {
    let tokencost = (count * tokenPrice) / 1000 / engineCosts[settings.engine];
    let update = { tokens: count, cost: tokencost };
    setTotalTokens(update);
  };

  function GetPrompt(){

    var promptID = window.location.href.split("prompt/")[1] ?? "";
   
    
    if(promptID != "" && promptLoaded === false ){
      var docRef = db.collection("prompts").doc(promptID);
      promptLoaded = true;
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("Document data:", doc.data());
               setDetails(doc.data());
          //   loadPrompt(doc.data(), doc.id);
              let data = doc.data();
              setMode(data.app);
              setSettings(data.settings);
              setPromptText(data.prompt);
              let display = document.getElementById("PromptDisplay");
              display.value =data.prompt
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
  }
  }
 
  GetPrompt();
  

  const apiCallButton = (reload) => {
    console.log("Send to API:", promptText);
    

    let text = "";

    if(reload){
      document.getElementById("PromptDisplay").value = lastPrompt;
    } else {
      lastPrompt = document.getElementById("PromptDisplay").value
    }

    if (apiKey === "" || apiKey == undefined) {
      alert("Please enter an API key");
    } else if (mode === "chat") {
      let messageInput = document.getElementById("ChatInput");

      if (messageInput.value.trim() !== "") {
        // Get the message from the input and add the stop sequence to it: default = "You:"
        let newMessage = settings.stop + " " + messageInput.value;

        // Add the message to the prompt window.
        document.getElementById("PromptDisplay").value +=
          newMessage.trim() + "\n";

        // Add the message to the messages window
        ProcessChatMessages(newMessage);

        // Set text to equal the prompt
        text = document.getElementById("PromptDisplay").value;

        // Clear the message input
        messageInput.value = "";

        APIcaller(text);
      } else {
        text = document.getElementById("PromptDisplay").value;
        APIcaller(text);
      }
    } else if (mode === "question") {
      let question = document.getElementById("QAinput").value;

      text =
        document.getElementById("PromptDisplay").value +
        settings.stop +
        " " +
        question;
      APIcaller(text);
    } else {
      text = document.getElementById("PromptDisplay").value;
      APIcaller(text);
    }
  };

  const APIcaller = (_text) => {
    let button = document.getElementById("SubmitButton");
    let showLoader = document.getElementById("Loading");
    console.log("Sending...");

    const axios = require("axios");

    showLoader.style.display = "inline-block";
    button.style.display = "none";

    var _data = {
      context: _text,
      length: parseInt(50),
      top_p: parseFloat(0),
      temperature: parseFloat(0.3),
      frequency_penalty: parseFloat(0),
      presence_penalty: parseFloat(0),
      best_of: parseInt(1),
      completions: 1,
      stream: false,
      logprobs: 1,
      stop: settings.stop,
    };

    var _headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${apiKey}`,
    };

    axios(`https://api.openai.com/v1/engines/${settings.engine}/generate`, {
      method: "POST",
      dataType: "JSON",
      headers: _headers,
      data: JSON.stringify(_data),
    })
      .then(function (response) {
        button.style.display = "inline-block";
        showLoader.style.display = "none";

        // Get the response text and strip away the prompt
        let text = response.data.data[0].text.join("").replace(_text, "");

        updateTokens(response.data.data[0].tokens.length);

        console.log("Engine:", settings.engine);
        console.log("Cost:", engineCosts[settings.engine]);

        // Send the response text top the handler
        ResponseHandler(text);
      })
      .catch(function (error) {
        button.style.display = "inline-block";
        showLoader.style.display = "none";
        console.log(error);
      });
  };

  const ResponseHandler = (text) => {
    if (mode === "chat") {
      let display = document.getElementById("PromptDisplay");
      display.value += text;

      ProcessChatMessages(text);
    } else if (mode === "question") {
      let question = document.getElementById("QAinput").value;
      //document.getElementById("QAinput").value = "";

      document.getElementById("QAresponse").innerText = text
        .split("A:")
        .reverse()[0];

      console.log("Question mode text:", text);
    } else {
      console.log("Document mode text:", text);
      let display = document.getElementById("PromptDisplay");
      display.value += text;
    }
  };

  function Menu() {
    return (
      <div>
        <div id="Menu">
         
          { adminMode ? <Account user={user} /> : ""  }
           
          <div
            onClick={() => {
              window.location.href = "/docs/";
            }}
            style={{ float: "right", cursor: "pointer", paddingLeft: "20px" }}
          >
            Documentation
          </div>


          <div
            onClick={() => {
              window.location.href = "/docs/#/?id=introduction";
            }}
            style={{ float: "right", cursor: "pointer", paddingLeft: "20px" }}
          >
            About
          </div>


          <div
            onClick={() => {
              window.location.href = "https://prompt.directory";
            }}
            style={{ float: "right", cursor: "pointer", paddingLeft: "20px" }}
          >
            Prompt Directory
          </div>
          <div
            onClick={() => {
              window.location.href = "https://gpttools.com";
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            GPT Tools
          </div>
        </div>
      </div>
    );
  }

  const CloseAll = () => {
    //document.getElementById("PromptWindow").style.display = "none";

    document.getElementById("ChatWindow").style.display = "none";
    document.getElementById("QAWindow").style.display = "none";
  };

  const ProcessChatMessages = (text) => {
    var textArray = text.split("\n");

    let NewMessages = document.getElementById("NewMessages");

    console.log("Formatting...");

    textArray.forEach((line) => {
      if (line.includes(":")) {
        let sender = line.split(":")[0];
        let messageText = line.split(":")[1];

        let div = document.createElement("div");
        div.classList.add("message");

        let senderDiv = document.createElement("div");
        let messageDiv = document.createElement("div");

        if (sender.trim() === settings.stop + ":" || sender.trim() === "You") {
          senderDiv.classList.add("sender-you");
          messageDiv.classList.add("message-you");
        } else {
          senderDiv.classList.add("sender-friend");
          messageDiv.classList.add("message-friend");
        }
        senderDiv.innerHTML = sender;
        messageDiv.innerHTML = messageText;
        div.appendChild(senderDiv);
        div.appendChild(messageDiv);
        NewMessages.appendChild(div);
      }

      document.getElementById("DummyScroll").scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    });
  };

  const ProcessQA = (text) => {
    let responseWindow = document.getElementById("QAresponse");
    responseWindow.innerText = text.split(settings.start).reverse()[0];
  };

  const setPrompt = (text) => {
    setPromptText(text);
    //console.log("Prompt text:", text);
  };

  const setDetails = (details) => {
    setPromptDetails(details);
  };

  const setKey = (newKey) => {
    setApiKey(newKey);
    console.log("New key:", apiKey);
  };

  const changeSetting = (setting, value) => {
    var newSettings = settings;
    newSettings[setting] = value;
    setSettings(newSettings);
  };

  const setAllSettings = (newSettings) => {
    setSettings(newSettings);
    console.log("Change settings:", settings);
  };

  const setMode = (_mode) => {
    if (_mode === "continuous") {
      _mode = "document";
    }

    if (_mode === "chat compact") {
      _mode = "chat";
    }

    document.getElementById("ModeSelect").value = _mode;

    setModeValue(_mode);

    CloseAll();

    switch (_mode) {
      case "document":
        console.log("Document");
        document.getElementById("PromptWindow").style.display = "block";
        break;
      case "chat":
        console.log("Chat");

        // Clear messages
        document.getElementById("NewMessages").innerHTML = "";
        document.getElementById("ChatWindow").style.display = "block";

        changeSetting("stop", "You:");
        changeSetting("start", "Friend:");

        let _prompt = document.getElementById("PromptDisplay").value;

        ProcessChatMessages(_prompt);

        break;
      case "question":
        console.log("Question");
        document.getElementById("QAWindow").style.display = "block";

        changeSetting("stop", "Q:");
        changeSetting("start", "A:");

        break;
      default:
    }
  };

  return (
    <div className="App">
      <Menu user={user} />

      <Sidebar
        setSettings={setAllSettings}
        setMode={setMode}
        openModal={openModal}
        setDetails={setDetails}
        setAllSettings={setAllSettings}
      />

      <Workspace
        settings={settings}
        changeSetting={changeSetting}
        setKey={setKey}
        promptText={promptText}
        setPrompt={setPrompt}
        apiCallButton={apiCallButton}
        mode={mode}
        setMode={setMode}
        engines={engines}
        // ToggleStats={ToggleStats}
        // ToggleEstimate={ToggleEstimate}
        ApiKeyHandler={ApiKeyHandler}
        PromptWindow={PromptWindow}
        ChatWindow={ChatWindow}
        QAWindow={QAWindow}
        // StatsWindow={StatsWindow}
        SubmitBar={SubmitBar}
        totalTokens={totalTokens}
        user={user}
        openModal={openModal}
      />
      <div id="ShowLoader" className="loader" style={{ display: "none" }}></div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          promptDetails={promptDetails}
          // overlayClassName="Overlay"
        >
          <button
            style={{
              float: "right",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            close
          </button>

 

          {showSaveOptions ? (
            <SaveOptionsModal settings={settings} promptText={promptText} promptDetails={promptDetails}/>
          ) : (
            <DetailModal promptDetails={promptDetails} subtitle={subtitle} />
          )}
        </Modal>
      </div>
    </div>
  );
}

function SaveOptionsModal({settings, promptText, promptDetails}) {
  const [firebaseID, setfirebaseID] = useState("");
  const [title, setTitle] = useState("");
  const [website, setWebsite] = useState("")
  const [app, setApp] = useState("");
  const [icon, setIcon] = useState("");
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [keywords, setKeywords] = useState("");
  
  const handleSubmit = (evt) => {
      evt.preventDefault();
      console.log(`Submitting Name ${title}`);



      let _data = {
        title: title,
        author: author,
        author_website: website ,
        icon: icon,
        description: description,
        category: category, 
        keywords: keywords.split(",") ?? [],
        prompt: promptText,
        settings: settings,
        app: app,
        featured: false,
        official: false,
        starter: false,
        approved: false,
   
      }

      if(title === "" || description === ""){
        alert("Please add a title and description");
      } else if(firebaseID === ""){
        // New prompt
          db.collection("prompts").add(_data)
          .then(function(docRef) {
              alert("New prompt added");
              console.log("Document written with ID: ", docRef.id);
          })
          .catch(function(error) {
              alert("Error");
              console.error("Error adding document: ", error);
          });

      } else {
         // Update prompt
          db.collection("prompts").doc(firebaseID).update(_data)
          .then(function(docRef) {
              alert("Prompt updated");
              console.log("Document written with ID: ", firebaseID);
          })
          .catch(function(error) {
              alert("Error");
              console.error("Error adding document: ", error);
          });

      }



 
  }
  return (
    <div id="SaveForm">
      <form onSubmit={handleSubmit}>

      <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={firebaseID}
            onChange={e => setfirebaseID(e.target.value)}
            placeholder="ID"
          />
        </label>


        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.title}
            onChange={e => setTitle(e.target.value)}
            placeholder="Title"
          />
        </label>

        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.icon}
            onChange={e => setIcon(e.target.value)}
            placeholder="Icon"
          />
        </label>

        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.app}
            onChange={e => setApp(e.target.value)}
            placeholder="App type"
          />
        </label>

        <label className="detail-input">
          <input
           className="detail-input"
            type="text"
            value={promptDetails.category}
            onChange={e => setCategory(e.target.value)}
            placeholder="Category"
          />
        </label>

        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.keywords ? promptDetails.keywords.join(",") : ""}
            onChange={e => setKeywords(e.target.value)}
            placeholder="Keywords"
          />
        </label>

        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.author}
            onChange={e => setAuthor(e.target.value)}
            placeholder="Author"
          />
        </label>

        <label className="detail-input">
          <input
            className="detail-input"
            type="text"
            value={promptDetails.author_website}
            onChange={e => setWebsite(e.target.value)}
            placeholder="Website"
          />
        </label>

        <label className="detail-input">
          <textarea

            type="text"
            value={promptDetails.description}
            onChange={e => setDescription(e.target.value)}
            placeholder="Description"
          /> 
        </label>


        


        <br></br>
        <br></br>
        <input className="button" type="submit" value="Submit" />
      </form>
    </div>
  );
}

function DetailModal({ promptDetails, subtitle}) {
 
    return (
      <div id="PromptDetailsModal">
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            {promptDetails.icon} {promptDetails.title}
          </h2>
        <div className="prompt-info">
          Category: <b>{promptDetails.category}</b>{" "}
        </div>
        <div className="prompt-info">
          Author: <b>{promptDetails.author}</b>{" "}
        </div>
        <div className="description">{promptDetails.description}</div>

        <div className="detail-settings">
          {promptDetails.settings &&
            Object.entries(promptDetails.settings).map(([key, value]) => (
              <div key={key}>
                {" "}
                {key}: {value}
              </div>
            ))}
        </div>
        <div
          className="copy-prompt"
          onClick={() => CopyPrompt(promptDetails.prompt)}
        >
          <i className="far fa-copy"></i>
        </div>
        <div className="prompt-display">{promptDetails.prompt}</div>
      </div>
    );
  
}

function Account({ user }) {
  return (
    <div style={{ float: "right", cursor: "pointer", paddingLeft: "20px" }}>
      {user ? <SignOut /> : <SignIn />}
    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <button className="account-button" onClick={signInWithGoogle}>
      <i className="far fa-sign-in"></i>
    </button>
  );
}

function SignOut() {
  return (
    <div>
      <button className="account-button" onClick={() => auth.signOut()}>
        Sign out
      </button>
    </div>
  );
}

function CopyPrompt(text) {
  navigator.clipboard.writeText(text);
  alert("Copied");
}

function ChatWindow() {
  return (
    <div id="ChatWindow" style={{ display: "none" }}>
      <div id="ChatMessages">
        {/* <div className="message">
          <div className="sender-you">Andrew</div>
          <div className="message-you">Hello!</div>
        </div>

        <div className="message">
          <div className="sender-friend">Joe</div>
          <div className="message-friend">How are you doing? Great to hear from you.</div>
        </div> */}

        <div id="NewMessages"></div>

        <div id="DummyScroll" style={{ height: "60px" }}></div>
      </div>
      <input id="ChatInput"></input>
    </div>
  );
}

function QAWindow() {
  return (
    <div id="QAWindow" style={{ display: "none" }}>
      <div id="QAresponse">Response...</div>
      <input id="QAinput" placeholder="Ask a question"></input>
    </div>
  );
}

function PromptWindow({ promptText, setPrompt }) {
  return (
    <div id="PromptWindow" style={{ display: "block" }}>
      <textarea
        id="PromptDisplay"
        defaultValue={promptText}
        onChange={(e) => setPrompt(e.target.value)}
      ></textarea>
    </div>
  );
}

function SubmitBar({
  apiCallButton,
  mode,
  setMode,
  promptText,
  settings,
  user,
  openModal,
}) {
  return (
    <div id="SubmitBar">
      <div id="ModeSelector">
        <label className="settings-label">
          <select
            id="ModeSelect"
            className="mode-select"
            defaultValue={mode}
            onChange={(e) => setMode(e.target.value)}
          >
            {modes.map((_mode) => (
              <option key={_mode.id} value={_mode.name}>
                {_mode.name}
              </option>
            ))}
          </select>
          App mode
        </label>
      </div>

      <button
        className="download-button"
        onClick={() => DownloadPrompt({ settings })}
      >
        Download
      </button>

      {user ? <SaveOptions openModal={openModal} /> : ""}

      <ReloadButton apiCallButton={apiCallButton}/>


      <div id="Loading">
        <div id="ShowLoader" className="loader"></div>{" "}
      </div>


      <button
        id="SubmitButton"
        className="submit-button"
        onClick={() => apiCallButton()}
      >
        Submit
      </button>
    </div>
  );
}

function ReloadButton({apiCallButton}){

  return (
    <button
    className="download-button"
    onClick={() => apiCallButton(true)}
  >
    Reload
  </button>
  )
}

function SaveOptions({ openModal }) {
  return (
    <button
      className="download-button"
      onClick={() => openModal("saveOptions")}
    >
      Save options
    </button>
  );
}

// Download locally
function DownloadPrompt({ settings }) {
  let promptText = document.getElementById("PromptDisplay").value;
  var promptData = JSON.stringify(settings) + "\n\n" + promptText;

  var tmp = document.createElement("div");
  tmp.innerHTML = promptData;
  let driveData = tmp.textContent || tmp.innerText || "No data";

  var a = document.body.appendChild(document.createElement("a"));
  a.download = "Prompt_" + Date.now() + ".txt";
  a.href = "data:text/txt," + driveData; // Grab the HTML
  a.click(); // Trigger a click on the element
}

function ApiKeyHandler({ apiKey, setKey }) {
  function showKeyInput() {
    console.log("Clicked");
    const keyButton = document.getElementById("keyButton");
    const _keyInput = document.getElementById("keyInput");
    if (_keyInput.style.display === "inline-block") {
      _keyInput.style.display = "none";
      keyButton.innerHTML = "🗝️ API Key";
    } else {
      _keyInput.style.display = "inline-block";
      keyButton.innerHTML = "Set key";
    }
  }

  return (
    <div>
      <input
        id="keyInput"
        style={{
          display: "none",
          marginRight: "20px",
          padding: "4px",
          width: "200px",
        }}
        onChange={(e) => {
          setKey(e.target.value);
        }}
      ></input>
      <button
        id="keyButton"
        className="key-button"
        onClick={() => showKeyInput()}
      >
        🗝️ API Key
      </button>
    </div>
  );
}

export default App;
